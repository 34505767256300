// @flow
import React from "react";
import { ArtList, Heading} from "../../components";
import withAlgoliaConnect from "../../HOC/algolia-art-connect";


type Type = {
  filter: string,
  offset: number,
  length: number,
  framed?: boolean,
  cols?: number
};

const Component = ({ offset, length,cols,framed }: Type) => {
  return (
    <div>
      <Heading size={'sm'} heading="Related Items"/>
      <ArtList  itemStyle={1} showFilter={false} framed={framed} cols={cols} />
    </div>
  );
};


Component.defaultProps = {
  cols:3,
  framed:true
};

export default withAlgoliaConnect(process.env.ALG_INDEX_NAME)(Component);
