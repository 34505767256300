// @flow
import React from "react";
import { Grid } from "../../components";
import { compose } from "recompose";
import ActionTools from "./components/action-tools";

import PropTypes from "prop-types";
import { withAuth } from "../../HOC";

const { DetailImage } = Grid.DetailItem;

type Type = {
  title: string,
  thumbs: any,
  onNext?: Function,
  onPrev?: Function,
  onCollection?: Function,
  onEdit?: Function,
  hideImage?: boolean,
  location?: any,
  isAuth: any,
  editUrl?: string,
  location: any
};

const Component = ({
  title,
  thumbs,
  onNext,
  onPrev,
  onCollection,
  onEdit,
  editUrl,
  isAuth,
  hideImage,
  location
}: Type) => {
  return (
    <div>
      <DetailImage hideImage={hideImage} title={title} thumbs={thumbs} />
      <ActionTools
        onCollection={onCollection}
        onPrev={onPrev}
        onNext={onNext}
        onEdit={onEdit}
        editUrl={editUrl}
        location={location}
        isAuth={isAuth}
      />
    </div>
  );
};

Component.propTypes = {
  elementId: PropTypes.string,
  editUrl: PropTypes.string,
  onEdit: PropTypes.func,
  hideImage: PropTypes.bool,

};
Component.defaultProps = {};

export default withAuth('admin')(Component);
