//flow
import React from "react";
import PropTypes from "prop-types";
import store from "../../../stores";
import { Link,navigate } from "gatsby";
import { withHandlers } from "recompose";

type Type = {
  onNext?: Function,
  onPrev?: Function,
  onCollection?: Function,
  onEdit?: Function,
  location?: any,
  isAuth: any,
  editUrl?: string,
  location: any
};

const Component = ({isAuth,onPrev,onNext,editUrl,location,onEdit,onCollection}:Type) => {
  return (
    <div className="row">
      {(!store.uIStore.hidePrev(location.pathname) ||
        !store.uIStore.hideNext(location.pathname)) && (
        <div className="col-lg-4">
          {!store.uIStore.hidePrev(location.pathname) && (
            <Link
              onClick={onPrev}
              className="btn btn-primary-bordered margin-right-10"
              to={"#"}
            >
              <i className="fa fa-angle-left margin-right-5" />
              Prev
            </Link>
          )}

          {!store.uIStore.hideNext(location.pathname) && (
            <Link
              onClick={onNext}
              className="btn btn-primary-bordered"
              to={"#"}
            >
              Next
              <i className="fa fa-angle-right margin-left-5" />
            </Link>
          )}
        </div>
      )}
      <div className="col-lg-5">
        {isAuth && (
          <a
            onClick={onEdit}
            className="btn btn-primary-bordered margin-right-10"
            href={editUrl || "#"}
            target='_blank'
          >
            <i className="fa fa-user-edit" />
            Edit
          </a>
        )}
        <Link
          onClick={onCollection}
          className="btn btn-primary-bordered "
          to={"#"}
        >
          <i className="fa fa-th margin-right-5" />
          Collection
        </Link>
      </div>
    </div>
  );
};

Component.defaultProps = {};

export default withHandlers({
  onNext: ({ location }: any) => e => {
    e.preventDefault();
    const path = store.uIStore.getNextNav(location.pathname);
    path && navigate(path);
  },
  onPrev: ({ location }: any) => e => {
    e.preventDefault();
    const path = store.uIStore.getPrevNav(location.pathname);
    path && navigate(path);
  },
  onCollection: ({
                   location,
                   elementId
                 }: {
    location: { state: scrollPos },
    elementId: string
  }) => e => {
    e.preventDefault();
    const path = store.uIStore.collectionPath;
    path &&
    navigate(path, {
      state: { elementId, scrollPos: location.state.scrollPos }
    });
  }
})(Component);
