// @flow
import React from "react";
import {autorun} from 'mobx'
import { Grid, Comments,Comments2 } from "../components";
import Layout from "../components/layout";
import { compose } from "recompose";
import withLifeCycle from "@hocs/with-lifecycle";
import { graphql, navigateTo } from "gatsby";
import { prepareArtItems, addToVisitedItem } from "./helpers";
import store from "../stores";
import {RelatedItems,DetailInfo,DetailImage} from "../page-components/gallery-item";
import { ART_ITEMS_MAP,userHasRole,ART_INFO_ACCORDION_FIELDS } from "../util";
import type { IGraphqlGalleryItem } from "../definitions";
const COMMENT_APIKEY = process.env.COMMENTS_API2;
const RELATED_ITEMS_LENGTH = 12;

const Component = ({ data, location,isAuth }: any) => {
  const {
    item,
    title,
    images,
    elementId,
    editUrl,
    hideImage,
    url,
    artist
  } = prepareArtItems(data);
  const filters = `artType:${item.artType}`;
  return (
    <Layout>
      <section
        id="gallery-single-section"
        className="gallery-single-with-sidebar gs-sidebar-left"
      >
        <div className="gallery-single-inner tt-wrap">
          <div className="row col-lg-7">
            <DetailImage
              editUrl={editUrl}
              elementId={elementId}
              location={location}
              title={title}
              thumbs={images}
              hideImage={hideImage}
            />
            <div className="margin-top-40">
              {/*<Comments apiKey={COMMENT_APIKEY} />*/}
              <Comments2 projectId={COMMENT_APIKEY} commentId={'my-comment-box'}  />
            </div>
          </div>
          <div className="col-lg-5">
            {item && (
              <DetailInfo
                accordionMaps={ART_INFO_ACCORDION_FIELDS}
                titleMaps={{
                  provenance:"Provenance",
                  notes:'Notes',
                  exhibitionHistory:'Exhibition History',
                  publicationHistory: 'Publication History'
                }}
                title={title}
                itemMaps={ART_ITEMS_MAP}
                item={item}

              />
            )}
          </div>

          <div className="row margin-top-40">
            <div className={"col-lg-7"}>
              <RelatedItems
                filters={filters}
                page={store.uIStore.getPage(filters)}
                hitsPerPage={RELATED_ITEMS_LENGTH}
              />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

Component.propTypes = {};
Component.defaultProps = {};

export default compose(
  withLifeCycle({
    onDidMount({ data }: { isAuth: any, data: IGraphqlGalleryItem }) {
      const { showDetail, isPublished } = data.markdownRemark.frontmatter;
      autorun(()=>{
        if ((showDetail && isPublished) || userHasRole('admin')) {
          addToVisitedItem(data, store.uIStore);
        } else {
          navigateTo(store.uIStore.collectionPath);
        }
      })
    }
  })
)(Component);

export const galleryItemPageQuery = graphql`
  query getArtDetail($id: String) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        exhibitionHistory
        publicationHistory
        title
        artType
        materialMedium
        originCountry
        objectType
        technique
        datePeriod
        dimensions
        hideImage
        showDetail
        isFeatured
        isPublished
        provenance
        artist {
          firstname
          othernames
          lastname
        }
        irsDate
        measure
        accessionNumber
        images {
          alt
          src
        }
      }
    }
  }
`;
