// @flow
import { getFullnames, prepareImage } from "../../util";

import { omit } from "lodash";
import type { IGraphqlGalleryItem, IuIStore } from "../../definitions";
const TRANSFORMER = "f_auto,q_auto,b_white,c_pad,h_300,e_sharpen/";
const DEFAULT_MEASURE = "inches";

export const prepareArtItems = (data: IGraphqlGalleryItem) => {
  const {
    fields: { slug: url },
    frontmatter,html:notes
  } = data.markdownRemark;
  const item = {...omit(frontmatter, [
    "title",
    "images",
    "artist",
    "dimensions",
    "hideImage"
  ]),notes};

  const editUrl = url.replace('gallery','admin/#/collections/gallery/entries');
  const {
    title,
    images,
    accessionNumber: elementId,
    artist,
    hideImage
  } = frontmatter;
  item["artist"] = getFullnames(artist);
  item["dimensions"] = `${frontmatter.dimensions} ${frontmatter.measure ||
    DEFAULT_MEASURE}`;
  return { item, title, images, elementId, editUrl, hideImage, url, artist };
};

export const addToVisitedItem = (
  data: IGraphqlGalleryItem,
  uIStore: IuIStore
) => {
  const {
    fields: { slug: url },
    frontmatter
  } = data.markdownRemark;
  const { artist, title, images, hideImage, showDetail } = frontmatter;

  const src = images && images[0] && images[0].src;
  uIStore.addVisitedItem({
    title,
    subtitle: getFullnames(artist),
    url,
    src: prepareImage(src, TRANSFORMER),
    hideImage,
    showDetail
  });
};
