import React from "react";
import { Grid } from "../../components";
import PropTypes from "prop-types";
import { withAuth } from "../../HOC";

const Component = ({ isAuth, ...rest }) => {
  return <Grid.DetailItem.DetailInfo  {...rest} isAuth={isAuth} />;
};

Component.propTypes = {
  title: PropTypes.string,
  item: PropTypes.any,
  itemMaps: PropTypes.any,
  showNav: PropTypes.bool
};
Component.defaultProps = {};

export default withAuth("admin")(Component);
